import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import "../../styles/styles.scss";
import { getHospitalsForAdminManage } from "api/adminDashboardAPI";
import HospitalTable from "components/AdminHospitalTable";
import CONSTANTS from "common/constants";
import Pagination, { IPagination } from "components/Pagination";
import { HospitalCertifiedTable } from "components/AdminCertifiedTable";
import TableRowCount from "components/TableRowCount";
import SearchFilter from "components/SearchFilter";
import {
    getAllProgramCategories,
    getPgmMeasuresExportExcel,
    getProgramCategoriesByCountryId,
    getProgramFieldsById,
} from "api/hospitalProgramsAPI";
import setNegativeTabIndex from "common/dom";
import { getCountries, getStates } from "api/countriesAPI";
import FocusTrap from "focus-trap-react";
import Dropdown from "components/Dropdown";
import { showToast } from "components/Toast/toast.slice";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import SearchDropdown from "components/SearchDropdown";
import GroupedMultiSelectDropdown from "components/GroupedMultiSelectDropdown";
import SortTableColumnDropdown from "components/SortTableColumnDropdown";
import { HospitalManagementWrapper } from "./styled";
import Sidebar from "../Sidebar/Sidebar";
import { setRegion } from "../../../components/SelectDomesticInternational/region.slice.";
import {
    setProgramCategoryId,
    setSearchKey,
    setCountryCode,
    setStateCode,
    setSortOrganizations,
} from "../../../components/SearchFilter/searchFilter.slice";
import { showModal, gobalExportFile, wordChange } from "../../../common/utils";
import webWorkerScript from "../../../common/webWorker";

export const HospitalManagement = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    // REDUX variables
    const region = useSelector((state: any) => {
        return state.region;
    });
    const searchFilter = useSelector((state: any) => {
        return state.searchFilter;
    });
    // RFC STATE variables
    const [tab, setTab] = useState<string>(
        location?.state?.adminfacilityMangemnetTab || CONSTANTS.PENDING
    );
    const [isActive, setIsActive] = useState<boolean>(tab != "DEACTIVATED");
    const [pendingHospitals, setPendingHospitals] = useState<any>([]);
    const [registeredHospitals, setRegisteredHospitals] = useState<any>([]);
    const [renewingHospitals, setRenewingHospitals] = useState<any>([]);
    const [deactivatedHospitals, setDeactivatedHospitals] = useState<any>([]);
    const [certifiedHospitals, setCertifiedHospitals] = useState<any>([]);
    const [programCategory, setProgramCategory] = useState<any>(
        searchFilter.programCategory
    );
    const [country, setCountry] = useState<any>(searchFilter.countryCode);
    const [stateCd, setStateCd] = useState<any>(searchFilter.stateCode);
    const [programCategories, setProgramCategories] = useState<any>([]);
    const [mounted, setMounted] = useState<boolean>(false);
    const [sortInd, setSortInd] = useState<any>(location?.state?.pageSort);
    const [isCountryLoading, setIsCountryLoading] = useState<boolean>(false);
    const [isStateLoading, setIsStateLoading] = useState<boolean>(false);
    const [countryDropdown, setCountryDropdown] = useState<any>([]);
    const [stateDropdown, setStateDropdown] = useState<any>([]);
    const [pageNumber, setPageNumber] = useState<number>(
        location?.state?.adminfacilityMangemnetPage || 1
    );
    const paginationSetLimit: number = 5;
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
    const [roleValue, setRoleValue] = useState<string>("none");

    const [programCats, setProgramCats] = useState<any>([]);
    const [expProgram, setExpProgram] = useState<any>({});
    const [programName, setProgramName] = useState<any>("");
    const [expYear, setExpYear] = useState<any>(new Date().getFullYear());
    const [expOrgType, setExpOrgType] = useState<any>([
        CONSTANTS.ORG_TYPE.CERTIFIED,
        CONSTANTS.ORG_TYPE.DEACTIVATED,
        CONSTANTS.ORG_TYPE.REGISTERED,
        CONSTANTS.ORG_TYPE.RENEWING,
    ]);
    const [expInterval, setExpInterval] = useState<any>([
        "Quarterly",
        "Annual",
    ]);
    const [expBenchmark, setExpBenchmark] = useState<any>("");
    const [expRegFields, setExpRegFields] = useState<any>([]);
    const [selectedRegFields, setSelectedRegFields] = useState<any>([]);
    const [expPgmFields, setExpPgmFields] = useState<any>([]);
    const [initPgmFields, setInitPgmFields] = useState<any>([]);
    const [selectedPgmFields, setselectedPgmFields] = useState<any>([]);
    const [exportButtonState, setExportButtonState] = useState<boolean>(false);
    const [isCategoryLoading, setIsCategoryLoading] = useState<boolean>(false);
    const [isRegFieldsLoading, setIsRegFieldsLoading] =
        useState<boolean>(false);
    const [isPgnFieldsLoading, setIsPgnFieldsLoading] =
        useState<boolean>(false);
    const [isPgnFieldsDropdown, setIsPgnFieldsDropdown] =
        useState<boolean>(false);
    const exportBtnRestrict = localStorage.getItem("exportBtnRestrict");
    const exportBtnRestrictStatus = exportBtnRestrict === "true";
    const [exportButtonDisabled, setExportButtonDisabled] = useState<boolean>(
        exportBtnRestrictStatus
    );
    const currentYear = new Date().getFullYear();
    const [isExpOrgTypeRequired, setIsExpOrgTypeRequired] =
        useState<boolean>(false);
    const [isExpIntervalRequired, setIsExpIntervalRequired] =
        useState<boolean>(false);
    const getYears = () => {
        const years = [];
        for (let i = currentYear; i >= 2019; i--) {
            years.push(i);
        }
        const yearsDropdownMap: any = [];
        if (years && years.length > 0) {
            years.forEach((year: any) => {
                yearsDropdownMap.push({ label: year, value: year });
            });
        }
        return yearsDropdownMap;
    };
    const yearsDropdown: Array<object> = getYears();
    const onChangeProgram = (value: any) => {
        const selected: any = programCats.filter(
            (cat: any) => cat.value === value
        );
        setExpProgram({
            programId: selected[0].programId,
            categoryId: selected[0].value,
        });
        setProgramName(selected[0].label);
    };
    const onChangeExpYear = (value: any) => {
        setExpYear(value);
    };
    const onChangeExpOrgType = (event: any) => {
        const { value, checked } = event.target;
        checked
            ? setExpOrgType([...expOrgType, value])
            : setExpOrgType(expOrgType.filter((id: any) => id != value));
    };
    const onChangeExpInterval = (event: any) => {
        const { value, checked } = event.target;
        checked
            ? setExpInterval([...expInterval, value])
            : setExpInterval(expInterval.filter((id: any) => id != value));
    };
    const onChangeExpBenchmark = (event: any) => {
        const { value, checked } = event.target;
        if (checked) {
            setExpBenchmark(value);
        }
    };
    const defaultPagination: IPagination = {
        pageNumber: 1,
        pageSize: 25,
        isFirst: true,
        isLast: false,
        setPageNumber,
        paginationSetLimit,
    };
    const [pagination, setPagination] =
        useState<IPagination>(defaultPagination);
    const [clearFilters, setClearFilters] = useState<boolean>(false);
    const [
        exportProgramMeasureActivateInd,
        setExportProgramMeasureActivateInd,
    ] = useState<boolean>(false);

    const resetModalOnClose = () => {
        setIsPgnFieldsDropdown(false);
        setSelectedRegFields([]);
        setselectedPgmFields([]);
        setExpProgram("");
        setExpBenchmark("");
        setExpOrgType([
            CONSTANTS.ORG_TYPE.CERTIFIED,
            CONSTANTS.ORG_TYPE.DEACTIVATED,
            CONSTANTS.ORG_TYPE.REGISTERED,
            CONSTANTS.ORG_TYPE.RENEWING,
        ]);
        setExpYear(new Date().getFullYear());
        setExpInterval(["Quarterly", "Annual"]);
    };
    useEffect(() => {
        if (expOrgType.length !== 0) {
            setIsExpOrgTypeRequired(false);
        } else {
            setIsExpOrgTypeRequired(true);
        }
        if (expInterval.length !== 0) {
            setIsExpIntervalRequired(false);
        } else {
            setIsExpIntervalRequired(true);
        }
    }, [expOrgType, expInterval]);

    const getProgramFields = () => {
        setExpPgmFields(["none"]);
        const programFields: any = [{ label: "All", value: "All" }];
        setselectedPgmFields([]);
        initPgmFields.map((pgmField: any) => {
            programFields.push({
                label: wordChange(pgmField.title),
                value: pgmField.key,
            });
        });
        setExpPgmFields(programFields);
        setExpPgmFields(programFields);
    };

    const getCatFields = () => {
        setInitPgmFields(["none"]);
        getProgramFieldsById(expProgram.categoryId, expProgram.programId).then(
            (response: any) => {
                if (response.success) {
                    setInitPgmFields(response.data);
                }
            }
        );
    };
    useEffect(() => {
        if (initPgmFields.length != 0) {
            getProgramFields();
            setIsPgnFieldsLoading(false);
        } else {
            setExpPgmFields(["none"]);
        }
    }, [initPgmFields]);
    useEffect(() => {
        setIsPgnFieldsLoading(false);
        if (
            expProgram !== " " &&
            expProgram !== "" &&
            expProgram.programId !== undefined
        ) {
            setIsPgnFieldsDropdown(true);
            getCatFields();
        } else {
            setIsPgnFieldsDropdown(false);
        }
    }, [expProgram]);

    function registerFields() {
        const regFields: any = [{ label: "All", value: "All" }];
        setSelectedRegFields([]);
        CONSTANTS.REGISTRATION.map((regField: any) => {
            regFields.push({
                label: regField.label,
                value: regField.value,
            });
        });
        setExpRegFields(regFields);
        setIsRegFieldsLoading(false);
    }
    useEffect(() => {
        registerFields();
    }, []);

    useEffect(() => {
        let mountedInd = true;
        setIsTableLoading(true);
        if (mounted) {
            getHospitalsForAdminManage({
                status: tab,
                pageNumber,
                region: region.region,
                pageSize: pagination.pageSize,
                isActive,
                categoryId: programCategory,
                country,
                sortInd,
                stateCd,
                sortOrganizations: searchFilter.sortOrganizations,
                searchKey: searchFilter.searchKey,
            }).then((response: any) => {
                if (
                    mountedInd &&
                    response.success &&
                    response.statusCode === 200
                ) {
                    if (tab === CONSTANTS.PENDING) {
                        setNegativeTabIndex(
                            [
                                "certified-tab",
                                "registered-tab",
                                "renewing-tab",
                                "deactivated-tab",
                                "pending-tab",
                            ],
                            "pending-tab"
                        );
                        setPendingHospitals(response.data.hospitalPrograms);
                    } else if (tab === CONSTANTS.REGISTRED) {
                        setNegativeTabIndex(
                            [
                                "certified-tab",
                                "registered-tab",
                                "renewing-tab",
                                "deactivated-tab",
                                "pending-tab",
                            ],
                            "registered-tab"
                        );
                        setRegisteredHospitals(response.data.hospitalPrograms);
                    } else if (tab === CONSTANTS.RENEWING) {
                        setNegativeTabIndex(
                            [
                                "certified-tab",
                                "registered-tab",
                                "renewing-tab",
                                "deactivated-tab",
                                "pending-tab",
                            ],
                            "renewing-tab"
                        );
                        setRenewingHospitals(response.data.hospitalPrograms);
                    } else if (tab === CONSTANTS.CERTIFIED) {
                        setNegativeTabIndex(
                            [
                                "certified-tab",
                                "registered-tab",
                                "renewing-tab",
                                "deactivated-tab",
                                "pending-tab",
                            ],
                            "certified-tab"
                        );
                        setCertifiedHospitals(response.data.hospitalPrograms);
                    } else {
                        setNegativeTabIndex(
                            [
                                "certified-tab",
                                "registered-tab",
                                "renewing-tab",
                                "deactivated-tab",
                                "pending-tab",
                            ],
                            "deactivated-tab"
                        );
                        setDeactivatedHospitals(response.data.hospitalPrograms);
                    }
                    setPagination({
                        ...response.data._pagination,
                        setPageNumber,
                    });
                }
                setIsTableLoading(false);
                setRoleValue("alert");
            });
        }
        window.scrollTo(0, 0);
        return function cleanup() {
            mountedInd = false;
        };
    }, [pageNumber]);

    useEffect(() => {
        const countries = [{ label: "All", value: CONSTANTS.ALL }];
        const states = [{ label: "All", value: CONSTANTS.ALL }];
        setIsCountryLoading(true);
        getCountries().then((response) => {
            if (response?.data?.countries) {
                response.data.countries.map((item: any) => {
                    countries.push({
                        label: item.countryName,
                        value: item.countryCode,
                    });
                });
                setCountryDropdown(countries);
                setIsCountryLoading(false);
            }
        });
        setIsStateLoading(true);
        getStates("US").then((response) => {
            if (response?.data) {
                response.data.map((item: any) => {
                    states.push({
                        label: item.name,
                        value: item.name,
                    });
                });
                setStateDropdown(states);
                setIsStateLoading(false);
            }
        });
        window.onbeforeunload = function () {
            history.replace();
            return undefined;
        };
        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    const close = () => {
        history.push("/admin/hospital-management");
    };
    useEffect(() => {
        if (!mounted) {
            if (localStorage.getItem("resetFilter") == "true") {
                dispatch(setRegion(CONSTANTS.ALL));
                dispatch(setProgramCategoryId(CONSTANTS.ALL));
                dispatch(setSearchKey(""));
                dispatch(setCountryCode(CONSTANTS.ALL));
                setCountry(CONSTANTS.ALL);
                dispatch(setStateCode(CONSTANTS.ALL));
                setStateCd(CONSTANTS.ALL);
                setProgramCategory(CONSTANTS.ALL);
                getSearchedFacilities("", true);
            }
            setIsCategoryLoading(true);
            getAllProgramCategories().then((response: any) => {
                if (response.success) {
                    const selectedcategoriesMap: any = [
                        { label: "All", value: CONSTANTS.ALL },
                    ];
                    response.data.categories.forEach((data: any) => {
                        selectedcategoriesMap.push({
                            label: data.categoryName,
                            value: data.categoryId,
                            programId: data.programId,
                        });
                    });
                    setProgramCategories(selectedcategoriesMap);

                    const selectedcatsMap: any = [];
                    response.data.categories.forEach((data: any) => {
                        selectedcatsMap.push({
                            label: data.categoryName,
                            value: data.categoryId,
                            programId: data.programId,
                        });
                    });
                    setProgramCats(selectedcatsMap);
                    setMounted(true);
                    if (selectedcatsMap.length > 0) {
                        setIsCategoryLoading(false);
                    }
                    localStorage.setItem("resetFilter", "false");
                }
            });
        }
        if (!clearFilters && localStorage.getItem("resetFilter") == "false") {
            getSearchedFacilities(searchFilter.searchKey, false, !mounted);
        }
    }, [tab]);

    useEffect(() => {
        if (clearFilters) {
            getSearchedFacilities("", true);
        }
    }, [clearFilters]);

    const onkeyDownCallback = (event: any, name: string, total: number) => {
        let nextTarget;
        if (event.keyCode == 39) {
            // right arrow
            const activeEle: any = document.activeElement;
            if (parseInt(activeEle?.name?.split(name)[1]) == total) {
                nextTarget = 1;
            } else {
                nextTarget = parseInt(activeEle?.name?.split(name)[1]) + 1;
            }
            const ele: any = document.querySelectorAll(
                `button[name="tab_${nextTarget}"]`
            )[0];
            ele.focus();
        } else if (event.keyCode == 37) {
            // left arrow
            const activeEle: any = document.activeElement;
            if (parseInt(activeEle?.name?.split(name)[1]) == 1) {
                nextTarget = 5;
            } else {
                nextTarget = parseInt(activeEle?.name?.split(name)[1]) - 1;
            }
            const ele: any = document.querySelectorAll(
                `button[name="tab_${nextTarget}"]`
            )[0];
            ele.focus();
        }
    };

    const getFacilitiesResult = ({
        regionName,
        categoryId,
        countryCd,
        stateCode,
        search,
    }: any) => {
        setIsTableLoading(true);
        setPageNumber(1);
        setPagination(defaultPagination);
        getHospitalsForAdminManage({
            status: tab,
            pageNumber: 1,
            region: regionName || region.region,
            sortOrganizations:
                regionName ||
                (tab === CONSTANTS.CERTIFIED &&
                (searchFilter.sortOrganizations ===
                    CONSTANTS.EXPIRY_ASCENDING ||
                    searchFilter.sortOrganizations ===
                        CONSTANTS.EXPIRY_DESCENDING)
                    ? searchFilter.sortOrganizations
                    : (tab === CONSTANTS.CERTIFIED ||
                          tab === CONSTANTS.DEACTIVATED) &&
                      CONSTANTS.ALL),
            pageSize: pagination.pageSize,
            isActive,
            categoryId: categoryId || programCategory,
            country: countryCd || country,
            sortInd,
            stateCd: stateCode || stateCd,
            searchKey: search !== undefined ? search : searchFilter.searchKey,
        }).then((response: any) => {
            if (response.success && response.statusCode === 200) {
                switch (tab) {
                    case CONSTANTS.PENDING:
                        setNegativeTabIndex(
                            [
                                "certified-tab",
                                "registered-tab",
                                "renewing-tab",
                                "deactivated-tab",
                                "pending-tab",
                            ],
                            "pending-tab"
                        );
                        setPendingHospitals(response.data.hospitalPrograms);
                        break;
                    case CONSTANTS.REGISTRED:
                        setNegativeTabIndex(
                            [
                                "certified-tab",
                                "registered-tab",
                                "renewing-tab",
                                "deactivated-tab",
                                "pending-tab",
                            ],
                            "registered-tab"
                        );
                        setRegisteredHospitals(response.data.hospitalPrograms);
                        break;
                    case CONSTANTS.RENEWING:
                        setNegativeTabIndex(
                            [
                                "certified-tab",
                                "registered-tab",
                                "renewing-tab",
                                "deactivated-tab",
                                "pending-tab",
                            ],
                            "renewing-tab"
                        );
                        setRenewingHospitals(response.data.hospitalPrograms);
                        break;
                    case CONSTANTS.CERTIFIED:
                        setNegativeTabIndex(
                            [
                                "certified-tab",
                                "registered-tab",
                                "renewing-tab",
                                "deactivated-tab",
                                "pending-tab",
                            ],
                            "certified-tab"
                        );
                        setCertifiedHospitals(response.data.hospitalPrograms);
                        break;
                    default:
                        setNegativeTabIndex(
                            [
                                "certified-tab",
                                "registered-tab",
                                "renewing-tab",
                                "deactivated-tab",
                                "pending-tab",
                            ],
                            "deactivated-tab"
                        );
                        setDeactivatedHospitals(response.data.hospitalPrograms);
                        break;
                }
                setPagination({
                    ...response.data._pagination,
                    setPageNumber,
                });
            }
            setIsTableLoading(false);
            setClearFilters(false);
            if (mounted) {
                setRoleValue("alert");
            }
        });
    };
    const sortIndValue = (value: any) => {
        setSortInd(value);
    };

    const getSearchedFacilities = (
        value: string,
        clearFilterInd?: boolean,
        retainPageInd?: boolean
    ) => {
        setIsTableLoading(true);
        if (!retainPageInd) {
            setPageNumber(1);
            setPagination(defaultPagination);
        }
        getHospitalsForAdminManage({
            status: tab,
            pageNumber: retainPageInd ? pageNumber : 1,
            region: clearFilterInd ? CONSTANTS.ALL : region.region,
            pageSize: pagination.pageSize,
            isActive,
            categoryId: clearFilterInd ? CONSTANTS.ALL : programCategory,
            country: clearFilterInd ? CONSTANTS.ALL : country,
            sortInd,
            stateCd: clearFilterInd ? CONSTANTS.ALL : stateCd,
            searchKey: value,
            sortOrganizations:
                tab === CONSTANTS.CERTIFIED &&
                (searchFilter.sortOrganizations ===
                    CONSTANTS.EXPIRY_ASCENDING ||
                    searchFilter.sortOrganizations ===
                        CONSTANTS.EXPIRY_DESCENDING)
                    ? searchFilter.sortOrganizations
                    : (tab === CONSTANTS.CERTIFIED ||
                          tab === CONSTANTS.DEACTIVATED) &&
                      CONSTANTS.ALL,
        }).then((response: any) => {
            if (response.success && response.statusCode === 200) {
                if (tab === CONSTANTS.PENDING) {
                    setNegativeTabIndex(
                        [
                            "certified-tab",
                            "registered-tab",
                            "renewing-tab",
                            "deactivated-tab",
                            "pending-tab",
                        ],
                        "pending-tab"
                    );
                    setPendingHospitals(response.data.hospitalPrograms);
                } else if (tab === CONSTANTS.REGISTRED) {
                    setNegativeTabIndex(
                        [
                            "certified-tab",
                            "registered-tab",
                            "renewing-tab",
                            "deactivated-tab",
                            "pending-tab",
                        ],
                        "registered-tab"
                    );
                    setRegisteredHospitals(response.data.hospitalPrograms);
                } else if (tab === CONSTANTS.RENEWING) {
                    setNegativeTabIndex(
                        [
                            "certified-tab",
                            "registered-tab",
                            "renewing-tab",
                            "deactivated-tab",
                            "pending-tab",
                        ],
                        "renewing-tab"
                    );
                    setRenewingHospitals(response.data.hospitalPrograms);
                } else if (tab === CONSTANTS.CERTIFIED) {
                    setNegativeTabIndex(
                        [
                            "certified-tab",
                            "registered-tab",
                            "renewing-tab",
                            "deactivated-tab",
                            "pending-tab",
                        ],
                        "certified-tab"
                    );
                    setCertifiedHospitals(response.data.hospitalPrograms);
                } else {
                    setNegativeTabIndex(
                        [
                            "certified-tab",
                            "registered-tab",
                            "renewing-tab",
                            "deactivated-tab",
                            "pending-tab",
                        ],
                        "deactivated-tab"
                    );
                    setDeactivatedHospitals(response.data.hospitalPrograms);
                }
                setPagination({
                    ...response.data._pagination,
                    setPageNumber,
                });
            }
            setIsTableLoading(false);
            setClearFilters(false);
            if (mounted) {
                setRoleValue("alert");
            }
        });
    };

    const setDefaultPagination = () => {
        setPageNumber(1);
        setPagination(defaultPagination);
    };

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Tooltip) {
            CONSTANTS.WINDOW.AUI.Tooltip.init();
        }
    });

    const exportProgramMeasureModal = () => {
        setExportProgramMeasureActivateInd(true);
        showModal();
    };
    const exportProgramMeasureDismissModal = () => {
        setExportProgramMeasureActivateInd(false);
        resetModalOnClose();
        showModal();
    };

    const handleExport = (URL: any) => {
        const worker = new Worker(webWorkerScript);
        const WAIT_TIME = CONSTANTS.GLOBAL_EXPORTS_WAIT_TIME;
        setExportButtonDisabled(true);
        worker.addEventListener("message", (e: any) => {
            const type = e?.data?.type;
            const status = e?.data?.status;
            if (type === "apiResponse") {
                if (status === CONSTANTS.EXPORT_RESPONSE.IN_PROGRESS) {
                    localStorage.setItem("exportBtnRestrict", "true");
                    worker.postMessage({
                        type: "start",
                        authToken: localStorage.getItem("userAccessToken"),
                        URL,
                        time: WAIT_TIME,
                    });
                } else if (status === CONSTANTS.EXPORT_RESPONSE.SUCCESS) {
                    localStorage.setItem("exportBtnRestrict", "false");
                    setExportButtonDisabled(false);
                    gobalExportFile(
                        e?.data?.presignedUrl,
                        programName,
                        expYear
                    );
                    const toast = {
                        message:
                            "Organization dashboard successfully exported as Excel.",
                        code: "Success:",
                        type: "success",
                    };
                    dispatch(showToast(toast));
                } else if (status === CONSTANTS.EXPORT_RESPONSE.FAILED) {
                    localStorage.setItem("exportBtnRestrict", "false");
                    setExportButtonDisabled(false);
                    document
                        .getElementById("faild-message")
                        ?.append(
                            "Organization export is failed, Please try again!"
                        );
                    const alertPopup = document.getElementById("alertPopup");
                    alertPopup?.classList.remove("popupHide");
                }
            }
        });
        const toast = {
            message:
                "Organization measure export is in progress. Please don't refresh this page.",
            code: "Success:",
            type: "warning",
        };
        dispatch(showToast(toast));
        worker.postMessage({
            type: "start",
            authToken: localStorage.getItem("userAccessToken"),
            URL,
            time: WAIT_TIME,
        });
    };

    const handleExportToExcelEvent = (e: any) => {
        setExportButtonState(true);
        let finalRegFields: any = [];
        let finalPgmFields: any = [];
        finalRegFields = selectedRegFields
            .filter((itm: any) => itm.value != "All")
            .map((val: any) => val.value);
        finalPgmFields = selectedPgmFields
            .filter((itm: any) => itm.value != "All")
            .map((val: any) => val.value);
        e.preventDefault();
        const exportPayload: any = {
            year: `${expYear}`,
            Interval: expInterval,
            type: expOrgType,
            benchmarks: expBenchmark,
            registrationFields: finalRegFields,
            programFields: finalPgmFields,
            programCategoryName: programName,
        };
        const payload = JSON.parse(JSON.stringify(exportPayload));
        getPgmMeasuresExportExcel(expProgram, payload)
            .then((response) => {
                exportProgramMeasureDismissModal();
                setExportButtonState(false);
                const URL = `/admin/adminFacilityExports/${response.data.exportId}`;
                handleExport(URL);
            })
            .catch(() => {
                setExportButtonState(false);
            });
    };
    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Popover) {
            CONSTANTS.WINDOW.AUI.Popover.init();
        }
    });

    const isSafariBrowser = () => {
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            return true;
        }
        return false;
    };

    const eventHandler = (e: any) => {
        e.preventDefault();
    };
    return (
        <HospitalManagementWrapper className="container-fluid p-0 d-flex flex-grow-1">
            <div className="row no-gutters w-100 flex-grow-1">
                <Sidebar activeTab="hospitalManagement" />

                <section className="d-flex admin-main-content container">
                    <div className="row no-gutters w-100 admin-main">
                        <div className="col-12">
                            <div className="d-md-flex justify-content-between dashboard-fac-magnt align-items-center">
                                <h1 className="h2 font-400 mb-0">
                                    Organization Management
                                </h1>
                                <div
                                    id="adminExport"
                                    className="export-admin d-flex text-nowrap mt-3 mt-md-0"
                                >
                                    <button
                                        className="btn btn-text font-red global-export-btn"
                                        onClick={() => {
                                            !exportButtonDisabled
                                                ? exportProgramMeasureModal()
                                                : "";
                                        }}
                                        aria-describedby="globalExport"
                                    >
                                        <i className="aha-icon-export export-admin-icon mr-1" />
                                        Export
                                    </button>
                                    <div
                                        className="tooltip fade show bs-tooltip-bottom global-export-tooltip"
                                        role="tooltip"
                                        id="globalExport"
                                    >
                                        <div className="arrow" />
                                        <div className="tooltip-inner">
                                            {exportButtonDisabled
                                                ? "Please wait for the current export to complete"
                                                : "Export Program Measures"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {exportProgramMeasureActivateInd && (
                                <FocusTrap
                                    focusTrapOptions={{
                                        escapeDeactivates: false,
                                        clickOutsideDeactivates: false,
                                    }}
                                >
                                    <div
                                        className="modal fade show aui-modal"
                                        id="exportProgramMeasuresModal"
                                        tabIndex={-1}
                                        aria-labelledby="exportProgramMeasuresLabel"
                                        aria-modal="true"
                                        role="dialog"
                                        style={{
                                            display:
                                                exportProgramMeasureActivateInd
                                                    ? "block"
                                                    : "none",
                                        }}
                                    >
                                        <div className="modal-dialog modal-dialog-centered modal-md">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h2
                                                        className="h4"
                                                        id="exportProgramMeasuresLabel"
                                                    >
                                                        Export Program Measures
                                                    </h2>
                                                    <button
                                                        id="closeExportModal"
                                                        type="button"
                                                        className="close"
                                                        onClick={
                                                            exportProgramMeasureDismissModal
                                                        }
                                                        aria-label="Close export program measures modal"
                                                    >
                                                        <span
                                                            aria-hidden="true"
                                                            className="aha-icon-cross"
                                                        />
                                                    </button>
                                                </div>
                                                <div className="modal-body p-0">
                                                    <form
                                                        onSubmit={
                                                            handleExportToExcelEvent
                                                        }
                                                    >
                                                        <div className="d-flex flex-row-reverse">
                                                            <div className="pb-3 mand-field">
                                                                <sup>*</sup>
                                                                mandatory fields
                                                            </div>
                                                        </div>
                                                        <div className="form-group required flex-column">
                                                            <label
                                                                htmlFor="exportSelectProgram"
                                                                className="mb-1 font-600 label-form"
                                                                id="exportSelectProgram-label"
                                                            >
                                                                Select the
                                                                Program
                                                                <sup>*</sup>
                                                            </label>
                                                            <SearchDropdown
                                                                id="exportSelectProgram"
                                                                items={
                                                                    programCats
                                                                }
                                                                selectedValue={
                                                                    programCats.value
                                                                }
                                                                callParentOnSelect={
                                                                    onChangeProgram
                                                                }
                                                                isLoading={
                                                                    isCategoryLoading
                                                                }
                                                                label="Select Program"
                                                                mandatoryInd
                                                            />
                                                        </div>
                                                        <div className="form-group required flex-column">
                                                            <label
                                                                htmlFor="exportCalendarYear"
                                                                className="mb-1 font-600 label-form"
                                                                id="exportCalendarYear-label"
                                                            >
                                                                Select Calendar
                                                                Year
                                                                <sup>*</sup>
                                                            </label>
                                                            <Dropdown
                                                                id="exportCalendarYear"
                                                                items={
                                                                    yearsDropdown
                                                                }
                                                                selectedValue={
                                                                    expYear
                                                                }
                                                                callParentOnSelect={
                                                                    onChangeExpYear
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            className="form-group required flex-column"
                                                            role="group"
                                                            aria-labelledby="organizationType"
                                                        >
                                                            <label
                                                                className="mb-1 font-600 mt-1 label-form"
                                                                id="organizationType"
                                                            >
                                                                Select
                                                                Organization
                                                                Type(s) to
                                                                export
                                                                <sup>*</sup>
                                                            </label>
                                                            <ul className="d-sm-flex p-0">
                                                                <li className="form-check mb-3 mb-sm-2">
                                                                    <input
                                                                        name="registered"
                                                                        type="checkbox"
                                                                        value="Registred"
                                                                        checked={expOrgType.includes(
                                                                            CONSTANTS
                                                                                .ORG_TYPE
                                                                                .REGISTERED
                                                                        )}
                                                                        id="registered"
                                                                        onChange={
                                                                            onChangeExpOrgType
                                                                        }
                                                                        required={
                                                                            isExpOrgTypeRequired
                                                                        }
                                                                    />
                                                                    <label htmlFor="registered">
                                                                        Registered
                                                                    </label>
                                                                </li>
                                                                <li className="form-check mb-3 mb-sm-2 ml-sm-4">
                                                                    <input
                                                                        name="certified"
                                                                        type="checkbox"
                                                                        value="Certified"
                                                                        checked={expOrgType.includes(
                                                                            CONSTANTS
                                                                                .ORG_TYPE
                                                                                .CERTIFIED
                                                                        )}
                                                                        id="certified"
                                                                        onChange={
                                                                            onChangeExpOrgType
                                                                        }
                                                                        required={
                                                                            isExpOrgTypeRequired
                                                                        }
                                                                    />
                                                                    <label htmlFor="certified">
                                                                        Certified
                                                                    </label>
                                                                </li>

                                                                <li className="form-check mb-3 mb-sm-2 ml-sm-4">
                                                                    <input
                                                                        name="renewing"
                                                                        type="checkbox"
                                                                        value="Renewing"
                                                                        checked={expOrgType.includes(
                                                                            CONSTANTS
                                                                                .ORG_TYPE
                                                                                .RENEWING
                                                                        )}
                                                                        id="renewing"
                                                                        onChange={
                                                                            onChangeExpOrgType
                                                                        }
                                                                    />
                                                                    <label htmlFor="renewing">
                                                                        Renewing
                                                                    </label>
                                                                </li>
                                                                <li className="form-check mb-3 mb-sm-2 ml-sm-4">
                                                                    <input
                                                                        name="deactivated"
                                                                        type="checkbox"
                                                                        value="Dectivated"
                                                                        checked={expOrgType.includes(
                                                                            CONSTANTS
                                                                                .ORG_TYPE
                                                                                .DEACTIVATED
                                                                        )}
                                                                        id="deactivated"
                                                                        onChange={
                                                                            onChangeExpOrgType
                                                                        }
                                                                        required={
                                                                            isExpOrgTypeRequired
                                                                        }
                                                                    />
                                                                    <label htmlFor="deactivated">
                                                                        Deactivated
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="row">
                                                            <div
                                                                className="col-md-6 form-group required flex-column"
                                                                role="group"
                                                                aria-labelledby="selectInterval"
                                                            >
                                                                <label
                                                                    className="mb-1 font-600 label-form"
                                                                    id="selectInterval"
                                                                >
                                                                    Select
                                                                    Interval
                                                                    <sup>*</sup>
                                                                </label>
                                                                <ul className="d-flex p-0">
                                                                    <li className="form-check mb-2">
                                                                        <input
                                                                            name="expInterval"
                                                                            type="checkbox"
                                                                            value="Quarterly"
                                                                            id="quarterly"
                                                                            checked={expInterval.includes(
                                                                                "Quarterly"
                                                                            )}
                                                                            onChange={
                                                                                onChangeExpInterval
                                                                            }
                                                                            required={
                                                                                isExpIntervalRequired
                                                                            }
                                                                        />
                                                                        <label htmlFor="quarterly">
                                                                            Quarterly
                                                                        </label>
                                                                    </li>
                                                                    <li className="form-check mb-2 ml-4">
                                                                        <input
                                                                            name="expInterval"
                                                                            type="checkbox"
                                                                            value="Annual"
                                                                            id="annually"
                                                                            checked={expInterval.includes(
                                                                                "Annual"
                                                                            )}
                                                                            onChange={
                                                                                onChangeExpInterval
                                                                            }
                                                                            required={
                                                                                isExpIntervalRequired
                                                                            }
                                                                        />
                                                                        <label htmlFor="annually">
                                                                            Annual
                                                                        </label>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div
                                                                className="col-md-6 form-group required flex-column"
                                                                role="radiogroup"
                                                                aria-labelledby="viewBenchmark"
                                                            >
                                                                <label
                                                                    className="mb-1 font-600 label-form"
                                                                    id="viewBenchmark"
                                                                >
                                                                    View
                                                                    Benchmark
                                                                    <sup>*</sup>
                                                                </label>
                                                                <div className="d-flex">
                                                                    <div className="form-radio mb-2">
                                                                        <input
                                                                            name="benchmark"
                                                                            type="radio"
                                                                            value="true"
                                                                            id="benchmark-yes"
                                                                            onChange={
                                                                                onChangeExpBenchmark
                                                                            }
                                                                            required
                                                                        />
                                                                        <label htmlFor="benchmark-yes">
                                                                            Yes
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-radio mb-2 ml-4">
                                                                        <input
                                                                            name="benchmark"
                                                                            type="radio"
                                                                            value="false"
                                                                            id="benchmark-no"
                                                                            onChange={
                                                                                onChangeExpBenchmark
                                                                            }
                                                                            required
                                                                        />
                                                                        <label htmlFor="benchmark-no">
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-3">
                                                            <div className="form-group flex-column">
                                                                <label
                                                                    className="mb-1"
                                                                    id="dropdown-label-registeredField"
                                                                >
                                                                    Select
                                                                    registered
                                                                    fields to
                                                                    export
                                                                    (optional)
                                                                </label>
                                                                <GroupedMultiSelectDropdown
                                                                    hasAllOption
                                                                    id="registeredField"
                                                                    items={
                                                                        expRegFields
                                                                    }
                                                                    selectedValue={
                                                                        selectedRegFields
                                                                    }
                                                                    callParentOnSelect={(
                                                                        value: any
                                                                    ) => {
                                                                        setSelectedRegFields(
                                                                            value
                                                                        );
                                                                    }}
                                                                    isLoading={
                                                                        isRegFieldsLoading
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="form-group flex-column">
                                                                <label
                                                                    className="mb-1"
                                                                    id="dropdown-label-programField"
                                                                >
                                                                    Select
                                                                    program
                                                                    fields to
                                                                    export
                                                                    (optional)
                                                                </label>
                                                                <GroupedMultiSelectDropdown
                                                                    hasAllOption
                                                                    id="programField"
                                                                    items={
                                                                        expPgmFields
                                                                    }
                                                                    selectedValue={
                                                                        selectedPgmFields
                                                                    }
                                                                    callParentOnSelect={(
                                                                        value: any
                                                                    ) => {
                                                                        setselectedPgmFields(
                                                                            value
                                                                        );
                                                                    }}
                                                                    isLoading={
                                                                        isPgnFieldsLoading
                                                                    }
                                                                    disabled={
                                                                        !isPgnFieldsDropdown
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-end mt-4 flex-column flex-sm-row ">
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary btn-round mr-sm-4"
                                                                onClick={
                                                                    exportProgramMeasureDismissModal
                                                                }
                                                                aria-label="cancel and close export program measures modal"
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                disabled={
                                                                    exportButtonState
                                                                }
                                                                type="submit"
                                                                className={`btn btn-primary btn-round  mt-4  mt-sm-0 ${
                                                                    exportButtonState
                                                                        ? CONSTANTS.BUTTON_SPINNER
                                                                        : ""
                                                                }`}
                                                            >
                                                                <i className="aha-icon-export export-admin-icon mr-1" />
                                                                Export
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </FocusTrap>
                            )}
                            <div>
                                <ul
                                    className="nav nav-tabs tab-filled"
                                    id="myTab"
                                    role="tablist"
                                >
                                    <li
                                        className="nav-item tab-filled-item"
                                        role="presentation"
                                    >
                                        <button
                                            name="tab_1"
                                            onKeyDown={(event) => {
                                                onkeyDownCallback(
                                                    event,
                                                    "tab_",
                                                    5
                                                );
                                            }}
                                            className={`nav-link ${
                                                tab === CONSTANTS.PENDING
                                                    ? "active"
                                                    : ""
                                            }`}
                                            id="pending-tab"
                                            data-toggle="tab"
                                            data-target="#pending-approval-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="pending-approval-tab"
                                            aria-selected="true"
                                            onClick={() => {
                                                if (tab !== CONSTANTS.PENDING) {
                                                    setPageNumber(1);
                                                    setPagination(
                                                        defaultPagination
                                                    );
                                                    setTab(CONSTANTS.PENDING);
                                                    setIsActive(true);
                                                }
                                                if (
                                                    searchFilter.sortOrganizations ===
                                                        CONSTANTS.EXPIRY_ASCENDING ||
                                                    searchFilter.sortOrganizations ===
                                                        CONSTANTS.EXPIRY_DESCENDING
                                                ) {
                                                    dispatch(
                                                        setSortOrganizations(
                                                            CONSTANTS.ALL
                                                        )
                                                    );
                                                    setSortInd(undefined);
                                                }
                                            }}
                                        >
                                            Pending
                                        </button>
                                    </li>
                                    <li
                                        className="nav-item tab-filled-item"
                                        role="presentation"
                                    >
                                        <button
                                            name="tab_2"
                                            onKeyDown={(keyEvent) => {
                                                onkeyDownCallback(
                                                    keyEvent,
                                                    "tab_",
                                                    5
                                                );
                                            }}
                                            className={`nav-link ${
                                                tab === CONSTANTS.REGISTRED
                                                    ? "active"
                                                    : ""
                                            }`}
                                            id="registered-tab"
                                            data-toggle="tab"
                                            data-target="#registered-facilities-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="registered-facilities-tab"
                                            aria-selected="false"
                                            onClick={() => {
                                                if (
                                                    tab !== CONSTANTS.REGISTRED
                                                ) {
                                                    setPageNumber(1);
                                                    setPagination(
                                                        defaultPagination
                                                    );
                                                    setTab(CONSTANTS.REGISTRED);
                                                    setIsActive(true);
                                                }
                                                if (
                                                    searchFilter.sortOrganizations ===
                                                        CONSTANTS.EXPIRY_ASCENDING ||
                                                    searchFilter.sortOrganizations ===
                                                        CONSTANTS.EXPIRY_DESCENDING
                                                ) {
                                                    dispatch(
                                                        setSortOrganizations(
                                                            CONSTANTS.ALL
                                                        )
                                                    );
                                                    setSortInd(undefined);
                                                }
                                            }}
                                        >
                                            Registered
                                        </button>
                                    </li>
                                    <li
                                        className="nav-item tab-filled-item"
                                        role="presentation"
                                    >
                                        <button
                                            name="tab_3"
                                            onKeyDown={(keyEvent) => {
                                                onkeyDownCallback(
                                                    keyEvent,
                                                    "tab_",
                                                    5
                                                );
                                            }}
                                            className={`nav-link ${
                                                tab === CONSTANTS.RENEWING
                                                    ? "active"
                                                    : ""
                                            }`}
                                            id="renewing-tab"
                                            data-toggle="tab"
                                            data-target="#renewing-facilities-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="renewing-facilities-tab"
                                            aria-selected="false"
                                            onClick={() => {
                                                if (
                                                    tab !== CONSTANTS.RENEWING
                                                ) {
                                                    setPageNumber(1);
                                                    setPagination(
                                                        defaultPagination
                                                    );
                                                    setTab(CONSTANTS.RENEWING);
                                                    setIsActive(true);
                                                }
                                                if (
                                                    searchFilter.sortOrganizations ===
                                                        CONSTANTS.EXPIRY_ASCENDING ||
                                                    searchFilter.sortOrganizations ===
                                                        CONSTANTS.EXPIRY_DESCENDING
                                                ) {
                                                    dispatch(
                                                        setSortOrganizations(
                                                            CONSTANTS.ALL
                                                        )
                                                    );
                                                    setSortInd(undefined);
                                                }
                                            }}
                                        >
                                            Renewing
                                        </button>
                                    </li>
                                    <li
                                        className="nav-item tab-filled-item"
                                        role="presentation"
                                    >
                                        <button
                                            name="tab_4"
                                            onKeyDown={(keyDownEvent) => {
                                                onkeyDownCallback(
                                                    keyDownEvent,
                                                    "tab_",
                                                    5
                                                );
                                            }}
                                            className={`nav-link ${
                                                tab === CONSTANTS.CERTIFIED
                                                    ? "active"
                                                    : ""
                                            }`}
                                            id="certified-tab"
                                            data-toggle="tab"
                                            data-target="#certified-facilities-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="certified-facilities-tab"
                                            aria-selected="false"
                                            onClick={() => {
                                                if (
                                                    tab !== CONSTANTS.CERTIFIED
                                                ) {
                                                    setPageNumber(1);
                                                    setPagination(
                                                        defaultPagination
                                                    );
                                                    setTab(CONSTANTS.CERTIFIED);
                                                    setIsActive(true);
                                                }
                                            }}
                                        >
                                            Certified
                                        </button>
                                    </li>
                                    <li
                                        className="nav-item tab-filled-item"
                                        role="presentation"
                                    >
                                        <button
                                            name="tab_5"
                                            onKeyDown={(
                                                onkeyDownCallbackEvent
                                            ) => {
                                                onkeyDownCallback(
                                                    onkeyDownCallbackEvent,
                                                    "tab_",
                                                    5
                                                );
                                            }}
                                            className={`nav-link ${
                                                tab === CONSTANTS.DEACTIVATED
                                                    ? "active"
                                                    : ""
                                            }`}
                                            id="deactivated-tab"
                                            data-toggle="tab"
                                            data-target="#deactivated-facilities-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="deactivated-facilities-tab"
                                            aria-selected="false"
                                            onClick={() => {
                                                if (
                                                    tab !==
                                                    CONSTANTS.DEACTIVATED
                                                ) {
                                                    setPageNumber(1);
                                                    setPagination(
                                                        defaultPagination
                                                    );
                                                    setTab(
                                                        CONSTANTS.DEACTIVATED
                                                    );
                                                    setIsActive(false);
                                                }
                                                if (
                                                    searchFilter.sortOrganizations ===
                                                        CONSTANTS.EXPIRY_ASCENDING ||
                                                    searchFilter.sortOrganizations ===
                                                        CONSTANTS.EXPIRY_DESCENDING
                                                ) {
                                                    dispatch(
                                                        setSortOrganizations(
                                                            CONSTANTS.ALL
                                                        )
                                                    );
                                                    setSortInd(undefined);
                                                }
                                            }}
                                        >
                                            Deactivated
                                        </button>
                                    </li>
                                </ul>
                                <div
                                    className="tab-content admin-main-tabs"
                                    id="myTabContent"
                                >
                                    {tab === CONSTANTS.PENDING && (
                                        <div
                                            className={`tab-pane fade ${
                                                tab === CONSTANTS.PENDING
                                                    ? "show active"
                                                    : ""
                                            }`}
                                            id="pending-approval-tab"
                                            role="tabpanel"
                                            aria-labelledby="pending-tab"
                                            tabIndex={0}
                                        >
                                            <SearchFilter
                                                placeholder="By Organization Name/Code"
                                                programCategories={
                                                    programCategories
                                                }
                                                programCategory={
                                                    programCategory
                                                }
                                                setProgramCategory={
                                                    setProgramCategory
                                                }
                                                setDefaultPagination={
                                                    setDefaultPagination
                                                }
                                                country={country}
                                                setCountry={setCountry}
                                                stateCd={stateCd}
                                                setStateCd={setStateCd}
                                                tabName={CONSTANTS.PENDING}
                                                setClearFilters={
                                                    setClearFilters
                                                }
                                                setSortInd={setSortInd}
                                                countryDropdown={
                                                    countryDropdown
                                                }
                                                stateDropdown={stateDropdown}
                                                isCountryLoading={
                                                    isCountryLoading
                                                }
                                                isStateLoading={isStateLoading}
                                                getFacilitiesResult={
                                                    getFacilitiesResult
                                                }
                                            />
                                            <div className="mb-3 d-md-flex justify-content-between align-items-end">
                                                <TableRowCount
                                                    isFirst={pagination.isFirst}
                                                    pageNumber={
                                                        pagination.pageNumber
                                                    }
                                                    pageSize={
                                                        pagination.pageSize
                                                    }
                                                    isLast={pagination.isLast}
                                                    totalCount={
                                                        pagination.totalCount
                                                    }
                                                    roleValue={roleValue}
                                                />
                                                <div className="mt-3 mt-md-0">
                                                    <SortTableColumnDropdown
                                                        getHospitalsForAdminManage={
                                                            getHospitalsForAdminManage
                                                        }
                                                        setPendingHospitals={
                                                            setPendingHospitals
                                                        }
                                                        status={tab}
                                                        pageNumber={pageNumber}
                                                        pageSize={
                                                            pagination.pageSize
                                                        }
                                                        isActive={isActive}
                                                        categoryId={
                                                            programCategory
                                                        }
                                                        searchKey={
                                                            searchFilter.searchKey
                                                        }
                                                        sortIndValue={
                                                            sortIndValue
                                                        }
                                                        id="pendingSort"
                                                        stateCd={stateCd}
                                                        country={country}
                                                    />
                                                </div>
                                            </div>
                                            {isTableLoading ? (
                                                <div
                                                    role="alert"
                                                    aria-live="assertive"
                                                    aria-label="pending approval table is loading"
                                                    className="sr-only"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            <HospitalTable
                                                tab={tab}
                                                pageNumber={pageNumber}
                                                hospitals={pendingHospitals}
                                                actionURL="/admin/organization-management/approve-organization/"
                                                isTableLoading={isTableLoading}
                                                isActive={isActive}
                                                isDeactivated={false}
                                                close={close}
                                                setSortInd={setSortInd}
                                                sortInd={sortInd}
                                            />
                                        </div>
                                    )}
                                    {tab === CONSTANTS.REGISTRED && (
                                        <div
                                            className={`tab-pane fade ${
                                                tab === CONSTANTS.REGISTRED
                                                    ? "show active"
                                                    : ""
                                            }`}
                                            id="registered-facilities-tab"
                                            role="tabpanel"
                                            tabIndex={0}
                                            aria-labelledby="registered-tab"
                                        >
                                            <SearchFilter
                                                placeholder="By Organization Name/Code"
                                                programCategories={
                                                    programCategories
                                                }
                                                programCategory={
                                                    programCategory
                                                }
                                                setProgramCategory={
                                                    setProgramCategory
                                                }
                                                setDefaultPagination={
                                                    setDefaultPagination
                                                }
                                                country={country}
                                                setCountry={setCountry}
                                                stateCd={stateCd}
                                                setStateCd={setStateCd}
                                                setClearFilters={
                                                    setClearFilters
                                                }
                                                setSortInd={setSortInd}
                                                tabName={CONSTANTS.REGISTRED}
                                                countryDropdown={
                                                    countryDropdown
                                                }
                                                stateDropdown={stateDropdown}
                                                isCountryLoading={
                                                    isCountryLoading
                                                }
                                                isStateLoading={isStateLoading}
                                                getFacilitiesResult={
                                                    getFacilitiesResult
                                                }
                                            />
                                            <div className="mb-3 d-md-flex justify-content-between align-items-end">
                                                <TableRowCount
                                                    isFirst={pagination.isFirst}
                                                    pageNumber={
                                                        pagination.pageNumber
                                                    }
                                                    pageSize={
                                                        pagination.pageSize
                                                    }
                                                    isLast={pagination.isLast}
                                                    totalCount={
                                                        pagination.totalCount
                                                    }
                                                    roleValue={roleValue}
                                                />
                                                <div className="mt-3 mt-md-0">
                                                    <SortTableColumnDropdown
                                                        getHospitalsForAdminManage={
                                                            getHospitalsForAdminManage
                                                        }
                                                        setRegisteredHospitals={
                                                            setRegisteredHospitals
                                                        }
                                                        status={tab}
                                                        pageNumber={pageNumber}
                                                        pageSize={
                                                            pagination.pageSize
                                                        }
                                                        isActive={isActive}
                                                        categoryId={
                                                            programCategory
                                                        }
                                                        searchKey={
                                                            searchFilter.searchKey
                                                        }
                                                        sortIndValue={
                                                            sortIndValue
                                                        }
                                                        id="registeredSort"
                                                        stateCd={stateCd}
                                                        country={country}
                                                    />
                                                </div>
                                            </div>
                                            {isTableLoading ? (
                                                <div
                                                    role="alert"
                                                    aria-label="registered organizations table is loading"
                                                    className="sr-only"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            <HospitalTable
                                                tab={tab}
                                                pageNumber={pageNumber}
                                                hospitals={registeredHospitals}
                                                actionURL="/admin/organization-management/organization-details/"
                                                isTableLoading={isTableLoading}
                                                isActive={isActive}
                                                isDeactivated={false}
                                                close={close}
                                                setSortInd={setSortInd}
                                                sortInd={sortInd}
                                            />
                                        </div>
                                    )}
                                    {tab === CONSTANTS.RENEWING && (
                                        <div
                                            className={`tab-pane fade ${
                                                tab === CONSTANTS.RENEWING
                                                    ? "show active"
                                                    : ""
                                            }`}
                                            id="renewing-facilities-tab"
                                            role="tabpanel"
                                            tabIndex={0}
                                            aria-labelledby="renewing-tab"
                                        >
                                            <SearchFilter
                                                placeholder="By Organization Name/Code"
                                                programCategories={
                                                    programCategories
                                                }
                                                programCategory={
                                                    programCategory
                                                }
                                                setProgramCategory={
                                                    setProgramCategory
                                                }
                                                setDefaultPagination={
                                                    setDefaultPagination
                                                }
                                                country={country}
                                                setCountry={setCountry}
                                                stateCd={stateCd}
                                                setStateCd={setStateCd}
                                                setClearFilters={
                                                    setClearFilters
                                                }
                                                setSortInd={setSortInd}
                                                tabName={CONSTANTS.RENEWING}
                                                countryDropdown={
                                                    countryDropdown
                                                }
                                                stateDropdown={stateDropdown}
                                                isCountryLoading={
                                                    isCountryLoading
                                                }
                                                isStateLoading={isStateLoading}
                                                getFacilitiesResult={
                                                    getFacilitiesResult
                                                }
                                            />
                                            <div className="mb-3 d-md-flex justify-content-between align-items-end">
                                                <TableRowCount
                                                    isFirst={pagination.isFirst}
                                                    pageNumber={
                                                        pagination.pageNumber
                                                    }
                                                    pageSize={
                                                        pagination.pageSize
                                                    }
                                                    isLast={pagination.isLast}
                                                    totalCount={
                                                        pagination.totalCount
                                                    }
                                                    roleValue={roleValue}
                                                />
                                                <div className="mt-3 mt-md-0">
                                                    <SortTableColumnDropdown
                                                        getHospitalsForAdminManage={
                                                            getHospitalsForAdminManage
                                                        }
                                                        setRenewingHospitals={
                                                            setRenewingHospitals
                                                        }
                                                        status={tab}
                                                        pageNumber={pageNumber}
                                                        pageSize={
                                                            pagination.pageSize
                                                        }
                                                        isActive={isActive}
                                                        categoryId={
                                                            programCategory
                                                        }
                                                        searchKey={
                                                            searchFilter.searchKey
                                                        }
                                                        sortIndValue={
                                                            sortIndValue
                                                        }
                                                        id="renewingSort"
                                                        stateCd={stateCd}
                                                        country={country}
                                                    />
                                                </div>
                                            </div>
                                            {isTableLoading ? (
                                                <div
                                                    role="alert"
                                                    aria-label="renewed organizations table is loading"
                                                    className="sr-only"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            <HospitalTable
                                                tab={tab}
                                                pageNumber={pageNumber}
                                                hospitals={renewingHospitals}
                                                actionURL="/admin/organization-management/organization-details/"
                                                isTableLoading={isTableLoading}
                                                isActive={isActive}
                                                isDeactivated={false}
                                                close={close}
                                                setSortInd={setSortInd}
                                                sortInd={sortInd}
                                            />
                                        </div>
                                    )}
                                    {tab === CONSTANTS.CERTIFIED && (
                                        <div
                                            className={`tab-pane fade ${
                                                tab === CONSTANTS.CERTIFIED
                                                    ? "show active"
                                                    : ""
                                            }`}
                                            id="certified-facilities-tab"
                                            role="tabpanel"
                                            tabIndex={0}
                                            aria-labelledby="certified-tab"
                                        >
                                            <SearchFilter
                                                placeholder="By Organization Name/Code"
                                                programCategories={
                                                    programCategories
                                                }
                                                programCategory={
                                                    programCategory
                                                }
                                                setProgramCategory={
                                                    setProgramCategory
                                                }
                                                setDefaultPagination={
                                                    setDefaultPagination
                                                }
                                                setSortInd={setSortInd}
                                                country={country}
                                                setCountry={setCountry}
                                                stateCd={stateCd}
                                                setStateCd={setStateCd}
                                                tabName={CONSTANTS.CERTIFIED}
                                                setClearFilters={
                                                    setClearFilters
                                                }
                                                countryDropdown={
                                                    countryDropdown
                                                }
                                                stateDropdown={stateDropdown}
                                                isCountryLoading={
                                                    isCountryLoading
                                                }
                                                isStateLoading={isStateLoading}
                                                getFacilitiesResult={
                                                    getFacilitiesResult
                                                }
                                            />
                                            <div className="mb-3 d-md-flex justify-content-between align-items-end">
                                                <TableRowCount
                                                    isFirst={pagination.isFirst}
                                                    pageNumber={
                                                        pagination.pageNumber
                                                    }
                                                    pageSize={
                                                        pagination.pageSize
                                                    }
                                                    isLast={pagination.isLast}
                                                    totalCount={
                                                        pagination.totalCount
                                                    }
                                                    roleValue={roleValue}
                                                />
                                                <div className="mt-3 mt-md-0">
                                                    <SortTableColumnDropdown
                                                        getHospitalsForAdminManage={
                                                            getHospitalsForAdminManage
                                                        }
                                                        setCertifiedHospitals={
                                                            setCertifiedHospitals
                                                        }
                                                        status={tab}
                                                        pageNumber={pageNumber}
                                                        pageSize={
                                                            pagination.pageSize
                                                        }
                                                        isActive={isActive}
                                                        categoryId={
                                                            programCategory
                                                        }
                                                        searchKey={
                                                            searchFilter.searchKey
                                                        }
                                                        sortIndValue={
                                                            sortIndValue
                                                        }
                                                        id="certifiedSort"
                                                        stateCd={stateCd}
                                                        country={country}
                                                    />
                                                </div>
                                            </div>
                                            {isTableLoading ? (
                                                <div
                                                    role="alert"
                                                    aria-label="certified organizations table is loading"
                                                    className="sr-only"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            <HospitalCertifiedTable
                                                tab={tab}
                                                pageNumber={pageNumber}
                                                hospitals={certifiedHospitals}
                                                actionURL="/admin/organization-management/organization-details/"
                                                isTableLoading={isTableLoading}
                                                isActive={isActive}
                                                isDeactivated={false}
                                                setSortInd={setSortInd}
                                                sortInd={sortInd}
                                                close={close}
                                            />
                                        </div>
                                    )}
                                    {tab === CONSTANTS.DEACTIVATED && (
                                        <div
                                            className={`tab-pane fade ${
                                                tab === CONSTANTS.DEACTIVATED
                                                    ? "show active"
                                                    : ""
                                            }`}
                                            id="deactivated-facilities-tab"
                                            role="tabpanel"
                                            tabIndex={0}
                                            aria-labelledby="deactivated-tab"
                                        >
                                            <SearchFilter
                                                placeholder="By Organization Name/Code"
                                                programCategories={
                                                    programCategories
                                                }
                                                programCategory={
                                                    programCategory
                                                }
                                                setProgramCategory={
                                                    setProgramCategory
                                                }
                                                setDefaultPagination={
                                                    setDefaultPagination
                                                }
                                                setSortInd={setSortInd}
                                                country={country}
                                                setCountry={setCountry}
                                                stateCd={stateCd}
                                                setStateCd={setStateCd}
                                                tabName={CONSTANTS.DEACTIVATED}
                                                setClearFilters={
                                                    setClearFilters
                                                }
                                                countryDropdown={
                                                    countryDropdown
                                                }
                                                stateDropdown={stateDropdown}
                                                isCountryLoading={
                                                    isCountryLoading
                                                }
                                                isStateLoading={isStateLoading}
                                                getFacilitiesResult={
                                                    getFacilitiesResult
                                                }
                                            />
                                            <div className="mb-3 d-md-flex justify-content-between align-items-end">
                                                <TableRowCount
                                                    isFirst={pagination.isFirst}
                                                    pageNumber={
                                                        pagination.pageNumber
                                                    }
                                                    pageSize={
                                                        pagination.pageSize
                                                    }
                                                    isLast={pagination.isLast}
                                                    totalCount={
                                                        pagination.totalCount
                                                    }
                                                    roleValue={roleValue}
                                                />
                                                <div className="mt-3 mt-md-0">
                                                    <SortTableColumnDropdown
                                                        getHospitalsForAdminManage={
                                                            getHospitalsForAdminManage
                                                        }
                                                        setDeactivatedHospitals={
                                                            setDeactivatedHospitals
                                                        }
                                                        status={tab}
                                                        pageNumber={pageNumber}
                                                        pageSize={
                                                            pagination.pageSize
                                                        }
                                                        isActive={isActive}
                                                        categoryId={
                                                            programCategory
                                                        }
                                                        searchKey={
                                                            searchFilter.searchKey
                                                        }
                                                        sortIndValue={
                                                            sortIndValue
                                                        }
                                                        id="deactivatedSort"
                                                        stateCd={stateCd}
                                                        country={country}
                                                    />
                                                </div>
                                            </div>
                                            {isTableLoading ? (
                                                <div
                                                    role="alert"
                                                    aria-label="deactivated organizations table is loading"
                                                    className="sr-only"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            <HospitalTable
                                                tab={tab}
                                                pageNumber={pageNumber}
                                                hospitals={deactivatedHospitals}
                                                actionURL="/admin/organization-management/approve-organization/"
                                                isTableLoading={isTableLoading}
                                                isActive={isActive}
                                                isDeactivated
                                                close={close}
                                                setSortInd={setSortInd}
                                                sortInd={sortInd}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            {pagination && Number(pagination.totalPages) > 0 ? (
                                <Pagination
                                    pageNumber={pagination.pageNumber}
                                    pageSize={pagination.pageSize}
                                    totalCount={pagination.totalCount}
                                    totalPages={pagination.totalPages}
                                    isFirst={pagination.isFirst}
                                    isLast={pagination.isLast}
                                    setPageNumber={pagination.setPageNumber}
                                    paginationSetLimit={paginationSetLimit}
                                />
                            ) : null}
                        </div>
                    </div>
                </section>
            </div>
        </HospitalManagementWrapper>
    );
};

export default HospitalManagement;
